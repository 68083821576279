import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

const HeroVideo = () => {
  const data = useStaticQuery(graphql`{
  videoBgImage: file(relativePath: {eq: "bg/skylantern01.jpg"}) {
    childImageSharp {
      gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
    }
  }
}
`)

  console.log({ data })

  return (
    <div
      className="hero-wrap fl-wrap full-height scroll-con-sec hidden-section"
      id="sec1"
      data-scrollax-parent="true"
    >
      <div
        className="media-container"
        data-scrollax="properties: { translateY: '30%' }"
      >
        <div
          className="bg mob-bg"
          style={{
            backgroundImage: `url(${data.videoBgImage.childImageSharp.gatsbyImageData.originalImg})`,
          }}
        />
        {/*<div className="video-container">
        <video autoPlay loop muted className="bgvid">
          <source src="video/2.mp4" type="video/mp4" />
        </video>
      </div>*/}
        {/*<!-- <div class="background-vimeo" data-vim="97871257"> </div> -->*/}

        <div
          className="background-youtube-wrapper"
          data-vid="Wc8MJm1pOwE"
          data-mv="1"
        />
        <div className="overlay" />
      </div>
      <div className="half-hero-wrap">
        <div className="pr-bg" />
        <div className="rotate_text hero-decor-let">
          <div>Full Stack Development</div>
          <div>
            <span>UI/UX Design</span>
          </div>
          <div>Physical Computing</div>
          <div>
            <span>Virtual & Augmented Reality</span>
          </div>
          <div>Mobile Development</div>
        </div>
        <h1>
          Jerez Bain <br /> Toronto-based <br />
          <span>creative technologist</span>
          {/*form <span>USA</span>*/}
        </h1>
        <h4>
          I bring your ideas and designs to life. From small business websites
          to interactive web applications. My passion is building things and I
          put my best in every line of code, measurement, and decision.
        </h4>
        <div className="clearfix" />
        <a
          href="https://www.canva.com/design/DAC49gXa97k/iFQCqhL9bLmIKaoSNnjqmg/view"
          target="_blank"
          className="btn fl-btn custom-scroll-link  color-bg"
        >
          View My Resume
        </a>
      </div>
      <div className="hero-border hb-top" />
      <div className="hero-border hb-bottom" />
      <div className="hero-border hb-right" />
      <div className="hero-corner hiddec-anim" />
      <div className="hero-corner2 hiddec-anim" />
      <div className="hero-corner3 hiddec-anim" />
      <div className="scroll-down-wrap sdw_hero hiddec-anim">
        <div className="mousey">
          <div className="scroller" />
        </div>
        <span>Scroll down to discover</span>
      </div>
    </div>
  );
}

export default HeroVideo
