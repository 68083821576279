import * as React from "react"
import { TestimonyItemProps } from "../typings"

const TestimonyItem = ({ testimony, index }: TestimonyItemProps) => {
  const zeroPad = (num, places) => String(num).padStart(places, "0")

  return (
    <div className="testi-item fl-wrap">
      <div className="testi-avatar">
        <img
          src={testimony.image}
          alt={testimony.name}
          style={{ backgroundColor: "white" }}
        />
      </div>
      <span className="testi-number">.{zeroPad(index + 1, 2)}</span>
      <div className="testimonilas-text fl-wrap">
        <h3>{testimony.name}</h3>
        <p>{testimony.quote}</p>
        {testimony.link && (
          <a href={testimony.link.url} className="testi-link" target="_blank">
            {testimony.link.via}
          </a>
        )}
      </div>
    </div>
  )
}

export default TestimonyItem
