import React, { useEffect } from "react"
import { useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContentSection from "../components/Shared/ContentSection"
import TestimonialsSection from "../components/Shared/TestimonialsSection"
import ContentWrapper from "../components/Shared/ContentWrapper"
import HeroVideo from "../components/Heroes/Video"
import ResumeSection from "../components/Resume/Section"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      aboutImage: file(relativePath: { eq: "about.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  `)

  const [yearsOfExperience, setYoe] = useState(0)

  useEffect(() => {
    setYoe(new Date().getFullYear() - 2011)
  }, [])

  const Nav = () => (
    <div className="page-scroll-nav fl-wrap">
      <nav className="scroll-init color2-bg">
        <ul className="no-list-style">
          <li>
            <a
              className="scroll-link fbgs"
              href="#about"
              data-bgscr={data.aboutImage.childImageSharp.gatsbyImageData.src}
              data-bgtex="about"
            >
              <span>About</span>
            </a>
          </li>
          <li>
            <a
              className="scroll-link fbgs"
              href="#services"
              data-bgscr="images/bg/long/1.jpg"
              data-bgtex="services"
            >
              <span>Services</span>
            </a>
          </li>
          <li>
            <a
              className="scroll-link fbgs"
              href="#skills"
              data-bgscr="images/bg/long/1.jpg"
              data-bgtex="skills"
            >
              <span>Skills</span>
            </a>
          </li>
          <li>
            <a
              className="scroll-link fbgs"
              href="#resume"
              data-bgscr="images/bg/long/1.jpg"
              data-bgtex="resume"
            >
              <span>Resume</span>
            </a>
          </li>
          <li>
            <a
              className="scroll-link fbgs"
              href="#clients"
              data-bgscr="images/bg/long/1.jpg"
              data-bgtex="clients"
            >
              <span>Clients</span>
            </a>
          </li>
        </ul>
      </nav>
      <div className="arrowpagenav" />
    </div>
  )
  return (
    <Layout title="Home">
      <Seo title="Home" />
      <HeroVideo />

      <ContentWrapper nav={<Nav />}>
        {/*<!-- section -->*/}
        <ContentSection
          id="about"
          number={1}
          title="About Me"
          description="Hi there, I’m Jerez Bain and I love creating."
          decoratorPosition="top"
        >
          <div className="row">
            <div className="col-sm-5">
              <div className="dec-img fl-wrap">
                <GatsbyImage
                  image={data.aboutImage.childImageSharp.gatsbyImageData}
                  alt="Jerez Bain"
                />
              </div>
            </div>
            <div className="col-sm-7">
              <div className="main-about fl-wrap">
                {/*<h5>Curabitur convallis fringilla diam</h5>*/}
                <h2>
                  Every serious project
                  <br />
                  should take <span> design thinking </span> seriously
                </h2>
                <p>
                  I am a Creative Technologist from The Bahamas, living in
                  Toronto, and graduated from the{" "}
                  <a
                    href="https://www.ocadu.ca/admissions/programs/digital-futures"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Digital Futures Program
                  </a>{" "}
                  at{" "}
                  <a href="https://ocadu.ca" target="_blank" rel="noreferrer">
                    OCAD University
                  </a>{" "}
                  with a Bachelor of Design. I love making ideas come to life,
                  and learning how to bring aesthetics, functionality, and
                  simplicity together. I have a passion for cooking, extreme
                  flavors, sushi, Ultimate Frisbee. I have worked for a growing
                  list of clients and non-profit profit organizations for the
                  past 7 years in the industry and am always looking for
                  opportunities to better my skills and increase my coding
                  repertoire.
                </p>
                <div className="dec-list fl-wrap">
                  <ul>
                    <li>Empathize</li>
                    <li>Define (the problem)</li>
                    <li>Ideate</li>
                    <li>Prototype</li>
                    <li>Test</li>
                  </ul>
                </div>
                <Link to="portfolio" className="btn ajax fl-btn color-bg">
                  My Portfolio
                </Link>
              </div>
            </div>
          </div>
        </ContentSection>
        {/*<!-- section -->*/}
        <ContentSection id="none" sectionClasses="dark-bg bot-element">
          <div className="inline-facts-container fl-wrap">
            {/*<!-- inline-facts -->*/}
            <div className="inline-facts-wrap">
              <div className="inline-facts">
                <div className="milestone-counter">
                  <div className="stats animaper">
                    <div
                      className="num"
                      data-content="0"
                      data-num={yearsOfExperience}
                    >
                      0
                    </div>
                  </div>
                </div>
                <h6>Years of Experience</h6>
              </div>
            </div>
            {/*<!-- inline-facts end -->*/}
            {/*<!-- inline-facts  -->*/}
            <div className="inline-facts-wrap d-none">
              <div className="inline-facts">
                <div className="milestone-counter">
                  <div className="stats animaper">
                    <div className="num" data-content="0" data-num="357">
                      0
                    </div>
                  </div>
                </div>
                <h6>Happy customers</h6>
              </div>
            </div>
            {/*<!-- inline-facts end -->*/}
            {/*<!-- inline-facts  -->*/}
            <div className="inline-facts-wrap d-none">
              <div className="inline-facts">
                <div className="milestone-counter">
                  <div className="stats animaper">
                    <div className="num" data-content="0" data-num="825">
                      0
                    </div>
                  </div>
                </div>
                <h6>Working hours</h6>
              </div>
            </div>
            {/*<!-- inline-facts end -->*/}
            {/*<!-- inline-facts  -->*/}
            <div className="inline-facts-wrap d-none">
              <div className="inline-facts">
                <div className="milestone-counter">
                  <div className="stats animaper">
                    <div className="num" data-content="0" data-num="15">
                      0
                    </div>
                  </div>
                </div>
                <h6>Awards won</h6>
              </div>
            </div>
            {/*<!-- inline-facts end -->*/}
          </div>
        </ContentSection>
        {/*<!-- section end -->*/}
        <div className="section-separator bot-element">
          <span className="fl-wrap" />
        </div>
        {/*<!-- section  -->*/}
        <section
          className="hidden-section scroll-con-sec bot-element"
          id="services"
        >
          <div className="container">
            <div className="section-title fl-wrap">
              <h3>Services</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Maecenas in pulvinar neque. Nulla finibus lobortis pulvinar.{" "}
              </p>
            </div>
            <div className="cards-wrap fl-wrap">
              <div className="row">
                {/*<!-- card item -->*/}
                <div className="col-md-4">
                  <div className="content-inner fl-wrap">
                    <div className="content-front">
                      <div className="cf-inner">
                        <div
                          className="bg "
                          data-bg="images/services/1.jpg"
                        ></div>
                        <div className="overlay"></div>
                        <div className="inner">
                          <h2>Wed design</h2>
                          <ul>
                            <li>Concept</li>
                            <li>Design</li>
                            <li>3D Modeling</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="content-back">
                      <div className="cf-inner">
                        <div className="inner">
                          <div className="dec-icon">
                            <i className="fal fa-desktop"></i>
                          </div>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Maecenas in pulvinar neque. Nulla finibus
                            lobortis pulvinar.
                          </p>
                          <div className="serv-price-wrap">
                            <span>Price</span>$125-$335
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*<!-- card item end -->*/}
                {/*<!-- card item -->*/}
                <div className="col-md-4">
                  <div className="content-inner fl-wrap">
                    <div className="content-front">
                      <div className="cf-inner">
                        <div
                          className="bg "
                          data-bg="images/services/1.jpg"
                        ></div>
                        <div className="overlay"></div>
                        <div className="inner">
                          <h2>Branding</h2>
                          <ul>
                            <li>Concept</li>
                            <li>Design</li>
                            <li>3D Modeling</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="content-back">
                      <div className="cf-inner">
                        <div className="inner">
                          <div className="dec-icon">
                            <i className="fab fa-pagelines"></i>
                          </div>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Maecenas in pulvinar neque. Nulla finibus
                            lobortis pulvinar.
                          </p>
                          <div className="serv-price-wrap">
                            <span>Price</span>$200-$500
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*<!--card item end -->*/}
                {/*<!--card item -->*/}
                <div className="col-md-4">
                  <div className="content-inner fl-wrap">
                    <div className="content-front">
                      <div className="cf-inner">
                        <div
                          className="bg "
                          data-bg="images/services/1.jpg"
                        ></div>
                        <div className="overlay"></div>
                        <div className="inner">
                          <h2>Ui/Ux Design</h2>
                          <ul>
                            <li>Concept</li>
                            <li>Design</li>
                            <li>3D Modeling</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="content-back">
                      <div className="cf-inner">
                        <div className="inner">
                          <div className="dec-icon">
                            <i className="fal fa-mobile-android"></i>
                          </div>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Maecenas in pulvinar neque. Nulla finibus
                            lobortis pulvinar.
                          </p>
                          <div className="serv-price-wrap">
                            <span>Price</span>$225-$1300
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*<!--card item end -->*/}
              </div>
              <div className="srv-link-text fl-wrap">
                <h4>Ready to build your project? Visit my contact page: </h4>
                <Link to="/contact" className="btn float-btn ajax  color-bg">
                  Contacts
                </Link>
              </div>
            </div>
            <div className="sec-number">02.</div>
          </div>
        </section>
        {/*<!--section end -->*/}
        <div className="section-separator">
          <span className="fl-wrap"></span>
        </div>
        {/*<!--section  -->*/}
        <section className="hidden-section bot-element">
          <div className="container">
            <div className="section-title fl-wrap">
              <h3>Working Process</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Maecenas in pulvinar neque. Nulla finibus lobortis pulvinar.{" "}
              </p>
            </div>
            {/*<!--process-wrap  -->*/}
            <div className="process-wrap fl-wrap">
              <ul>
                <li>
                  <div className="time-line-icon">
                    <i className="fab fa-slideshare" />
                  </div>
                  <h4>Discuss the project</h4>
                  <div className="process-details">
                    <h6>Duis autem vel eum iriure dolor</h6>
                    <p>
                      Exerci tation ullamcorper suscipit lobortis nisl ut
                      aliquip ex ea commodo consequat. Duis autem vel eum iriure
                      dolor.
                    </p>
                  </div>
                  <span className="process-numder">01.</span>
                </li>
                <li>
                  <div className="time-line-icon">
                    <i className="fal fa-laptop"></i>
                  </div>
                  <h4>Develop & elaborate</h4>
                  <div className="process-details">
                    <h6>In ut odio libero, at vulputate urna. </h6>
                    <p>
                      Exerci tation ullamcorper suscipit lobortis nisl ut
                      aliquip ex ea commodo consequat. Duis autem vel eum iriure
                      dolor.
                    </p>
                  </div>
                  <span className="process-numder">02.</span>
                </li>
                <li>
                  <div className="time-line-icon">
                    <i className="fal fa-flag-checkered"></i>
                  </div>
                  <h4>Final approvement</h4>
                  <div className="process-details">
                    <h6>Nulla posuere sapien vitae lectus suscipit</h6>
                    <p>
                      Exerci tation ullamcorper suscipit lobortis nisl ut
                      aliquip ex ea commodo consequat. Duis autem vel eum iriure
                      dolor.
                    </p>
                  </div>
                  <span className="process-numder">03.</span>
                </li>
              </ul>
            </div>
            {/*<!-- process-wrap   end-->*/}
          </div>
        </section>
        {/*<!-- section end -->*/}
        <div className="section-separator bot-element">
          <span className="fl-wrap"></span>
        </div>
        {/*<!-- section  -->*/}
        <section className="scroll-con-sec bot-element" id="skills">
          <div className="container">
            <div className="section-title fl-wrap">
              <h3>My Skills</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Maecenas in pulvinar neque. Nulla finibus lobortis pulvinar.{" "}
              </p>
            </div>
            <div className="row">
              <div className="col-md-5">
                <div className="fl-wrap small-section-title">
                  <h3>Language Skills</h3>
                </div>
                <div
                  className="piechart-holder animaper"
                  data-skcolor="#F68338"
                >
                  {/*<!-- 1  -->*/}
                  <div className="piechart">
                    <span className="chart" data-percent="85">
                      <span className="percent"></span>
                    </span>
                    <div className="clearfix"></div>
                    <h4>French</h4>
                  </div>
                  {/*<!-- 1 end -->*/}
                  {/*<!-- 2  -->*/}
                  <div className="piechart">
                    <span className="chart" data-percent="95">
                      <span className="percent"></span>
                    </span>
                    <div className="clearfix"></div>
                    <h4>Dutch</h4>
                  </div>
                  {/*<!-- 2 end  -->*/}
                  {/*<!-- 3  -->*/}
                  <div className="piechart">
                    <span className="chart" data-percent="80">
                      <span className="percent"></span>
                    </span>
                    <div className="clearfix"></div>
                    <h4>Portugese</h4>
                  </div>
                  {/*<!-- 3  end-->*/}
                  {/*<!-- 3  -->*/}
                  <div className="piechart">
                    <span className="chart" data-percent="70">
                      <span className="percent"></span>
                    </span>
                    <div className="clearfix"></div>
                    <h4>Russian</h4>
                  </div>
                  {/*<!-- 3  end-->*/}
                </div>
              </div>
              <div className="col-md-7">
                <div className="fl-wrap small-section-title">
                  <h3>Developer Skills</h3>
                </div>
                <div className="skillbar-box animaper">
                  <div className="pr-bg pr-bg-white"></div>
                  {/*<!-- skill 1-->*/}
                  <div className="custom-skillbar-title">
                    <span>Photoshop</span>
                  </div>
                  <div className="skill-bar-percent">95%</div>
                  <div className="skillbar-bg" data-percent="95%">
                    <div className="custom-skillbar"></div>
                  </div>
                  {/*<!-- skill 2-->*/}
                  <div className="custom-skillbar-title">
                    <span>HTML/Css</span>
                  </div>
                  <div className="skill-bar-percent">65%</div>
                  <div className="skillbar-bg" data-percent="65%">
                    <div className="custom-skillbar"></div>
                  </div>
                  {/*<!-- skill 3-->*/}
                  <div className="custom-skillbar-title">
                    <span>3D MAX</span>
                  </div>
                  <div className="skill-bar-percent">95%</div>
                  <div className="skillbar-bg" data-percent="95%">
                    <div className="custom-skillbar"></div>
                  </div>
                  {/*<!-- skill 4-->*/}
                  <div className="custom-skillbar-title">
                    <span>PHP</span>
                  </div>
                  <div className="skill-bar-percent">70%</div>
                  <div className="skillbar-bg" data-percent="70%">
                    <div className="custom-skillbar"></div>
                  </div>
                  {/*<!-- skill 5-->*/}
                  <div className="custom-skillbar-title">
                    <span>Javascript</span>
                  </div>
                  <div className="skill-bar-percent">60%</div>
                  <div className="skillbar-bg" data-percent="60%">
                    <div className="custom-skillbar"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sec-number">03.</div>
          </div>
          <div className="col-wc_dec col-wc_dec2 col-wc_dec3"></div>
        </section>
        {/*<!-- section end -->*/}
        {/*<section className="dark-bg bot-element d-none">
          <div className="container">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="video-box dec-img fl-wrap">
                    <img src="images/all/1.jpg" alt="" className="respimg" />
                    <a
                      className="video-box-btn image-popup"
                      href="https://vimeo.com/34741214"
                    >
                      <i className="fas fa-play"></i>
                    </a>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="video-promo-text fl-wrap mar-top">
                    <h3>My Video Presentation </h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Maecenas in pulvinar neque. Nulla finibus lobortis
                      pulvinar. Donec a consectetur nulla. Nulla posuere sapien
                      vitae lectus suscipit, et pulvinar nisi tincidunt. Aliquam
                      erat volutpat.{" "}
                    </p>
                    <a href="#" className="btn fl-btn    color-bg">
                      My Youtube Chanel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hero-corner" />
        </section>*/}
        {/*<!-- section end -->*/}
        {/*<!-- section  -->*/}
        <ResumeSection />
        {/*<!-- section end -->*/}
        <div className="section-separator bot-element">
          <span className="fl-wrap" />
        </div>
        {/*<!-- section  -->*/}
        <TestimonialsSection id="clients" title="Testimonials" number={5} />
        {/*<!-- section end -->*/}
      </ContentWrapper>
    </Layout>
  )
}

export default IndexPage
