import * as React from "react"
import ContentSection from "../Shared/ContentSection"

const ResumeSection = () => {
  return (
    <ContentSection
      id="resume"
      number={4}
      sectionClasses="hidden-section scroll-con-sec bot-element"
      title="Resume"
      description="Versatile front-end developer with 8 years of experience developing websites and web applications across multiple industries. Proficient in HTML, SCSS and javascript - including modern languages like Vue, React, and Angular. Passionate about materializing ideas, design thinking, physical computing, and light installations."
    >
      <div className="custom-inner-holder">
        <div className="custom-inner">
          <div className="row">
            <div className="col-md-4">
              <div className="resum-header workres">
                <i className="fa fa-briefcase"></i>
                <h3> Soundscape Media Inc.</h3>
                <span> April 2018 - Present </span>
              </div>
            </div>
            <div className="col-md-8">
              <div className="resum-content fl-wrap">
                <h4>Full Stack Web Developer</h4>
                {/*<p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don't look
                  even slightly believable. If you are going to use a passage of
                  Lorem Ipsum, you need to be sure there isn't anything
                  embarrassing hidden in the middle of text. All the Lorem Ipsum
                  generators on the Internet tend to repeat predefined chunks as
                  necessary, making this the first true generator on the
                  Internet. It uses a dictionary of over 200 Latin words
                </p>*/}
                <div className="dec-list fl-wrap">
                  <ul>
                    <li>
                      Reformed an older jQuery-based music search engine
                      managing over 17K+ tracks into React.
                    </li>
                    <li>
                      Implementing additional filtering features, state
                      management, and extensibility while maintaining API
                      backward compatibility and improving performance.
                    </li>
                    {/*<li>Bring to the table win-win survival strategies.</li>*/}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="custom-inner">
          <div className="row">
            <div className="col-md-4">
              <div className="resum-header workres">
                <i className="fa fa-briefcase"></i>
                <h3> Orange Gate</h3>
                <span> Sept 2017 - May 2018 </span>
              </div>
            </div>
            <div className="col-md-8">
              <div className="resum-content fl-wrap">
                <h4>Web Developer (Freelance)</h4>
                {/*<p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don't look
                  even slightly believable. If you are going to use a passage of
                  Lorem Ipsum, you need to be sure there isn't anything
                  embarrassing hidden in the middle of text. All the Lorem Ipsum
                  generators on the Internet tend to repeat predefined chunks as
                  necessary, making this the first true generator on the
                  Internet. It uses a dictionary of over 200 Latin words
                </p>*/}
                <div className="dec-list fl-wrap">
                  <ul>
                    <li>
                      Responsible for materializing conceptual Management and
                      Data Reporting initiatives.
                    </li>
                    <li>
                      Created a CRM process visualization into an interactive
                      web application for enterprises and small businesses.
                    </li>
                    <li>
                      Developed a HR application the focuses on improving staff
                      productivity, accountability, and office culture.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="custom-inner">
          <div className="row">
            <div className="col-md-4">
              <div className="resum-header workres">
                <i className="fa fa-briefcase"></i>
                <h3> Missions.Me</h3>
                <span> May 2016 - Mar 2018 </span>
              </div>
            </div>
            <div className="col-md-8">
              <div className="resum-content fl-wrap">
                <h4>Front-End Developer (Contract)</h4>
                {/*<p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don't look
                  even slightly believable. If you are going to use a passage of
                  Lorem Ipsum, you need to be sure there isn't anything
                  embarrassing hidden in the middle of text. All the Lorem Ipsum
                  generators on the Internet tend to repeat predefined chunks as
                  necessary, making this the first true generator on the
                  Internet. It uses a dictionary of over 200 Latin words
                </p>*/}
                <div className="dec-list fl-wrap">
                  <ul>
                    <li>
                      Responsible for implementing designs into feature-rich
                      interactive user experiences.
                    </li>
                    <li>
                      Successfully redeveloped two websites into interactive web
                      applications, improved the fundraising capability and
                      overall user experience for 2,858+ volunteers in 33+
                      nations.
                    </li>
                    <li>
                      Created a modular content manager using VueJS similar to
                      popular WordPress page builder plugins such as 'WPBakery'.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="custom-inner d-none">
          <div className="row">
            <div className="col-md-4">
              <div className="resum-header workres">
                <i className="fa fa-briefcase"></i>
                <h3> Work in company "Dolore"</h3>
                <span> 2012-2016 </span>
              </div>
            </div>
            <div className="col-md-8">
              <div className="resum-content fl-wrap">
                <h4>Complete the project "domik"</h4>
                <p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don't look
                  even slightly believable. If you are going to use a passage of
                  Lorem Ipsum, you need to be sure there isn't anything
                  embarrassing hidden in the middle of text. All the Lorem Ipsum
                  generators on the Internet tend to repeat predefined chunks as
                  necessary, making this the first true generator on the
                  Internet. It uses a dictionary of over 200 Latin words
                </p>
                <div className="dec-list fl-wrap">
                  <ul>
                    <li>
                      Leverage agile frameworks to provide a robust synopsis.
                    </li>
                    <li>Iterative approaches to corporate strategy foster.</li>
                    <li>Bring to the table win-win survival strategies.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContentSection>
  )
}

export default ResumeSection
